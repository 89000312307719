<template>
    <div>
        <van-nav-bar
                placeholder
                fixed
                left-arrow
                :border='false'
                class="header"
                title="评价本书"
                @click-left="$router.go(-1)">
            <template #left>
                <van-icon name="arrow-left" size="25" color="#0A1C33"/>
            </template>
            <template #right>
                <p @click="addClick">发布</p>
            </template>
        </van-nav-bar>
        <div class="Evaluation">
            <van-rate
                    v-model="value"
                    :size="25"
                    :icon="img"
                    :void-icon="img1"
                    void-color="#eee"
                    @change="onChange"
            />
            <div class="Evaluation-text">
                <p v-if="value==1">不好</p>
                <p v-else-if="value==2">一般</p>
                <p v-else-if="value==3">还行</p>
                <p v-else-if="value==4">不错</p>
                <p v-else>力荐</p>
            </div>
        </div>
        <div class="div">
            <textarea v-model="msg" placeholder="请从故事情节，人物性格等方面打分并表达你的看法">

            </textarea>
        </div>

    </div>
</template>

<script>
    export default {
        name: "Evaluation",
        data() {
            return {
                img: require("../../assets/images/Stars_icon.png"),
                img1: require("../../assets/images/Stars_icon_50.png"),
                value: 5,
                msg: '',
                id: this.$route.query.id,
            }
        },
        methods: {
            addClick() {
                this.$axios.post('/api/comment/add', {
                    anime_id: this.id,
                    content: this.msg,
                    star: this.value,
                })
                    .then(res => {
                        if (res.data.code == 200) {
                            this.$toast.success('评论成功');
                            this.$router.go(-1)
                        }
                    })
                    .catch(err => {
                        this.$toast(err.response.data.message);
                    })
            },

            onChange(value) {
                this.value = value
            }
        }
    }
</script>

<style scoped>
    textarea::-webkit-input-placeholder {
        font-size: 28px;
        color: #BFC2CC;
        line-height: 44px;
    }

    textarea {
        border: none;
        background: #F2F5F8;
        height: 500px;
        width: 100%;
        padding: 40px 20px;
        resize: none;
        font-size: 28px;
        color: #303133;
    }

    .Evaluation-text {
        margin-top: 30px;
        font-size: 30px;
        color: #303133;
    }

    .Evaluation {
        padding: 60px 40px 40px;
        text-align: center;
    }
</style>